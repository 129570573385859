import React from 'react';
import Layout from '../landing/components/layout';
import SEO from '../landing/components/seo';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'x-memorista': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const GuestbookPage = () => (
  <Layout>
    <SEO title="Live demo" />

    <div className={'page-header'}>
      <h1>Live Demo</h1>
      <p>This guestbook has been created using Memorista.</p>
    </div>

    <div className="container">
      <div className="content">
        <x-memorista api-key="30acc9f9-afd3-4883-90cf-ce5971cf758d"></x-memorista>
      </div>
    </div>

    <div className={'call-to-action'}>
      <div className={'container'}>
        <div className={'call-to-action__content'}>
          <h2>Sign up for free</h2>
          <p>Start now and create your own personal Guestbook with Memorista in just 5 minutes.</p>
        </div>

        <div className={'button'}>
          <a href="/admin" target="_blank">
            Create Guestbook
          </a>
        </div>
      </div>
    </div>
  </Layout>
);

export default GuestbookPage;
